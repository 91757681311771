import React, { useState, useRef, useContext, lazy, Suspense } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container, Typography, Grid, makeStyles } from '@material-ui/core';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
//import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import FormModalContext from '../context/FormModalContext';
import { SEO } from '../components/SEO';
import { FAQ } from '../components/General/FAQ';
import { CtaCard } from '../components/General/CtaCard';

// import { Form } from '../components/ContactForm/Form';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	faqSection: {
		marginTop: '2em',
		[theme.breakpoints.down('lg')]: {
			marginTop: '0em',
		},
		[theme.breakpoints.down('md')]: {
			marginTop: '2em',
		},
		// [theme.breakpoints.down('sm')]: {
		// 	marginTop: '2em',
		// },
		// [theme.breakpoints.down('xs')]: {
		// 	marginTop: '2em',
		// },
	},
}));

const FAQPage = ({ data, location }) => {
	const faqData = data.faq.edges[0].node;
	const [expanded, setExpanded] = useState(0);
	const { formModalOpen } = useContext(FormModalContext);
	const faqRef = useRef(null);

	const classes = useStyles();

	const {
		metaTitle,
		metaDescription,
		hero,
		faq,
		faqDisclaimer,
		ctaBackground,
		ctaHeader,
		ctaSubheader,
		ctaText,
	} = faqData;
	//Think I want to clean and refactor this into smaller components for readability.

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} />
			<Container
				style={{ marginBottom: '15rem' }}
				className={classes.faqSection}>
				{faq.map((faq, index) => (
					<FAQ
						key={index}
						faq={faq}
						index={index}
						expanded={expanded}
						setExpanded={setExpanded}
						faqRef={faqRef}
					/>
				))}
				{faqDisclaimer && (
					<Typography variant='body1' style={{ lineHeight: '28px' }}>
						{faqDisclaimer}
					</Typography>
				)}
				<CtaCard
					background={ctaBackground}
					header={ctaHeader}
					subheader={ctaSubheader}
					ctaText={ctaText}
				/>
				{/* no form pages need generic pardot url */}
				{formModalOpen ? (
					<Form
						formId='4863'
						privacy
						noForm
						location={location}
						pardotUrl='https://go.getservman.com/l/1042281/2023-10-12/65r4qv'
					/>
				) : null}
			</Container>
			<WaveUpSVG fill='#f4f8ff' />
		</>
	);
};

export const query = graphql`
	query FAQPageQuery {
		faq: allSanityFaq {
			edges {
				node {
					title
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					faq {
						question
						_rawAnswer
					}
					faqDisclaimer
					ctaHeader
					ctaSubheader
					ctaText
					ctaBackground {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
				}
			}
		}
	}
`;

export default FAQPage;
